<template>
    <component :is="componentBlockInfo" :unitId="unitId"></component>

    <router-view :class="$attrs.class"></router-view>

    <Teleport to="#sectionInfoByPage" :key="componentBlockInfo">
        <router-view name="info"></router-view>
    </Teleport>

    <Teleport to="#sectionInfoByRoute">
        <router-view :class="$attrs.class" name="sectionInfoByRoute" v-on:close-dialog="onCloseSectionInfoByRoute"></router-view>
    </Teleport>
</template>

<script>
import UnitInfo_reefer from '@/components/units/pageInfo/UnitInfo_reefer.prometheus.vue';
import UnitInfo_info from '@/components/units/pageInfo/UnitInfo_content.prometheus.vue';
import {mapGetters} from "vuex";
import {accessRight} from "@/lib/access-rights";
import routeMixin from "@/mixins/routeMixin";
export default {
    name: "UnitInfo",
    inject: ['filter'],
    mixins: [routeMixin],
    props: {
        unitId: [String, Number],
    },
    data() {
        return {
            showInfo: true,
        }
    },
    components: {
        UnitInfo_info,
        UnitInfo_reefer,
    },
    computed: {
        ...mapGetters([
            "unitsByIds",
            "unitsAccessRightsByIds",
        ]),
        componentBlockInfo() {
            return this.routeMeta?.componentBlockInfo ||  'UnitInfo_info';
        },
        unit() {
            return this.unitsByIds[this.unitId]
        },
        hasVideo() {
            return !!this.unit?.video
        },
        isUnitAccessRight_edit() {
            return this.unitsAccessRightsByIds[this.unitId] >= accessRight.edit
        },
    },
    methods: {
        onCloseSectionInfoByRoute(){
            this.setRoute.returnToParent(this.$route, this.$router)
        },
    },
    watch: {
        routeName: {
            handler: function (nVal, OldVal){
                if(nVal != OldVal){
                    this.showInfo = true
                }
            },
            deep: true,
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
<style>
.show-info--close #sectionInfoByPage {
    display: none;
}
</style>