// import consts from "@/consts"
// import {arraySplitIntoChunks} from "@/lib/lib";

import {arrayFilter_OnlyUnique} from "@/lib/lib";
import i18n from "@/i18n";

export default {
    state: {
        screenshots_index: [],
        screenshots: [],
    },
    actions: {
        fetchLastScreenshots({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastScreenshots', time: Date.now() / 1000})

                let params = {withAddress: 1, ...args.params}
                //if(!args.force) params.lastTimes = getters.getScreenshotsLastTimeByUnitIds
                this.$api.screenshots.getLast(args.unitId, args.last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastScreenshots', inprogress: false})
                    });
            })
        },
        fetchScreenshots4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let index = getters.getScreenshotsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                    if (index) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchScreenshots4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastScreenshots', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.screenshots.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            if (!params.limit || response.data.length < params.limit) {
                                commit('setScreenshotsIndex', {...args, count: response.data.length})
                                if (!args.unitId && response.data.length) {
                                    response.data
                                        .map(e => e.unit_id)//toDo all units forEach
                                        .filter(arrayFilter_OnlyUnique)
                                        .forEach(unitId => {
                                            commit('setScreenshotsIndex', {...args, unitId, count: 1})
                                        })
                                }
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshots4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastScreenshots', inprogress: false})
                        }
                    });
            })
        },
        fetchScreenshots4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let screenshot = getters.getScreenshots.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                    if (screenshot) {
                        return resolve(true)
                    }
                }
                dispatch('setLastCall', {name: 'fetchScreenshots4Period', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastScreenshots', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.screenshots.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshots4Period', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastScreenshots', inprogress: false})
                        }
                    });
            })
        },
        fetchScreenshotsUnitsLastSuccessful({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {//!args.unitId ||
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchScreenshotsUnitsLastSuccessful', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                let params = {withAddress: 1, limit: 1, ...args.params}
                this.$api.screenshots.getLastSuccessful(args.unitId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshotsUnitsLastSuccessful', inprogress: false})
                    });
            })
        },

        //groups
        fetchLastScreenshotsUnitsGroup({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', time: Date.now() / 1000})

                let params = {withAddress: 1, ...args.params}
                //if(!args.force) params.lastTimes = getters.getScreenshotsLastTimeByUnitIds
                this.$api.UnitsGroupsScreenshots.getLast(args.groupId, args.last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', inprogress: false})
                    });
            })
        },
        fetchScreenshotsUnitsGroup4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {groupId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }

                // if (args.groupId > 0) {
                //     let index = getters.getScreenshotsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                //     if (index) {
                //         return resolve(true)
                //     }
                // }

                dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroup4Date', time: Date.now() / 1000})
                // if (args.date == getters.getDateToday) {
                //     dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', time: Date.now() / 1000})
                // }

                let params = {withAddress: 1, ...args.params}
                this.$api.UnitsGroupsScreenshots.get4Date(args.groupId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            if (!params.limit || response.data.length < params.limit) {
                                commit('setScreenshotsIndex', {...args, count: response.data.length})
                                if (!args.groupId && response.data.length) {
                                    response.data
                                        .map(e => e.unit_id)//toDo all units forEach
                                        .filter(arrayFilter_OnlyUnique)
                                        .forEach(unitId => {
                                            commit('setScreenshotsIndex', {...args, unitId, count: 1})
                                        })
                                }
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroup4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', inprogress: false})
                        }
                    });
            })
        },
        fetchScreenshotsUnitsGroup4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }
                // if (args.groupId > 0) {
                //     let screenshot = getters.getScreenshots.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                //     if (screenshot) {
                //         return resolve(true)
                //     }
                // }
                dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroup4Period', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', time: Date.now() / 1000})
                }

                let params = {withAddress: 1, ...args.params}
                this.$api.UnitsGroupsScreenshots.get4Period(args.groupId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroup4Period', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastScreenshotsUnitsGroup', inprogress: false})
                        }
                    });
            })
        },
        fetchScreenshotsUnitsGroupLastSuccessful({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {//!args.unitId ||
                    return reject(false)
                }
                // if (args.groupId > 0) {
                //     let screenshot = getters.getScreenshots.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                //     if (screenshot) {
                //         return resolve(true)
                //     }
                // }
                dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroupLastSuccessful', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                let params = {withAddress: 1, limit: 1, ...args.params}
                this.$api.UnitsGroupsScreenshots.getLastSuccessful(args.groupId, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeScreenshots', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchScreenshotsUnitsGroupLastSuccessful', inprogress: false})
                    });
            })
        },
        reloadScreenshots({commit, dispatch}, args) {
            commit('clearScreenshots')
            return dispatch('fetchScreenshots4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        setScreenshotsIndex(state, params) {
            let i = state.screenshots_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.screenshots_index.push(Object.freeze({...params}))
            } else {
                state.screenshots_index[i] = Object.freeze({...state.screenshots_index[i], ...params})
            }
        },
        storeScreenshots(state, nScreenshots) {
            nScreenshots = nScreenshots
                .filter((e, i, _screenshots) => (i === _screenshots.findIndex(_e => _e.xid == e.xid)))
                .filter(e => !state.screenshots.find(se => se.xid == e.xid))
                .map(e => Object.freeze(e))

            // if(nScreenshots.length) state.screenshots.push(...nScreenshots)
            if (!nScreenshots.length) return false
            if (!state.screenshots.length) {
                state.screenshots = nScreenshots
                return true
            }
            state.screenshots.push(...nScreenshots)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nScreenshots)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.screenshots.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearScreenshots(state) {
            state.screenshots = [];
            state.screenshots_index = [];
        },
    },
    getters: {
        getNewScreenshotsCount(state, getters) {
            let lastVisit = getters.getLastVisitNotifications
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.screenshots.filter(e => e.time?.utc > lastVisit).length
        },
        getScreenshotsIndex(state) {
            return state.screenshots_index
        },
        getScreenshots(state) {
            return state.screenshots
        },
        getScreenshotsI18nMessages(state) {
            return state.screenshots
                .map((screenshotsTextByXid, screenshot) => {
                    let message = screenshot?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = screenshot?.message[match[1]] || ''
                            let translateKey = ['message', screenshot.hw_type, str].join('.')
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: screenshot.type, xid: screenshot.xid, message};
                })
        },
        getScreenshotsI18nMessagesByXid(state) {
            return state.screenshots
                .reduce((screenshotsTextByXid, screenshot) => {
                    let message = screenshot?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = screenshot?.message[match[1]] || ''
                            let translateKey = ['message', screenshot.hw_type, str].join('.')
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    screenshotsTextByXid[screenshot.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return screenshotsTextByXid;
                }, {})
        },
        getScreenshotsLastTimeByUnitIds(state) {
            return state.screenshots
                //.filter(e => e?.time?.utc >= getters.getTimeToday)
                .reduce((timeByUnitIds, screenshot) => {
                    timeByUnitIds[screenshot.unit_id] = screenshot?.time?.utc > timeByUnitIds[screenshot.unit_id] ? screenshot?.time?.utc : timeByUnitIds[screenshot.unit_id]
                    return timeByUnitIds
                }, {})
        },
    }
}
