// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";

const with_removed = {'force[with_removed]': 1}//String(process.env.VUE_APP_PACKAGE).includes('admin') ? {'force[with_removed]': 1} : {}
const changedField = 'updated_at';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        insuranceReportsFullLoad: false,
        insuranceReportsLiteLoad: false,
        insuranceForms: [],
        insuranceReports: [],
    },
    actions: {
        fetchInsuranceReport/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchInsuranceReports', time: Date.now() / 1000})

                const params = getApiProps('insurance_reports', args)
                this.$api.insurancereports.find(args.id, {...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            //commit('updateInsuranceReport', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchInsuranceReports', inprogress: false})
                    });
            })
        },
        fetchInsuranceReports/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchInsuranceReports', time: Date.now() / 1000})

                const params = getApiProps('insurance_reports', args)
                this.$api.insurancereports.getAll({...params, ...with_removed})
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchInsuranceReports', inprogress: false})
                    });
            })
        },
        fetchInsuranceReportsAll({dispatch}) {
            dispatch('fetchInsuranceReportsAllLite', {})
        },
        fetchInsuranceReportsAll_({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isInsuranceReportsLiteLoad) {
                    return resolve(getters.getInsuranceReports.length)
                }
                this.$api.init.getInsuranceReports()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setInsuranceReports', response.data)
                            commit('setInsuranceReportsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchInsuranceReportsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchInsuranceReportsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isInsuranceReportsLiteLoad) {
                    return resolve(getters.getInsuranceReports.length)
                }
                dispatch('fetchInsuranceReports', {lite: true})
                    .then((data) => {
                        commit('updateInsuranceReports', data)
                        commit('setInsuranceReportsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchInsuranceReportsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchInsuranceReportsAllPages({dispatch, commit, getters}, args) {
            //dispatch('setLastCall', {name: 'fetchInsuranceReportsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchInsuranceReportsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.getInsuranceReports.length) {
                        return resolve([])
                    }

                    let pageSize = consts.querySettings.pageSize
                    let items = getters.getInsuranceReports.length
                    let pages = Math.ceil( items / pageSize)
                    if(pages > 8) {
                        pageSize = Math.ceil(items / consts.querySettings.maxPages)
                        pages = Math.ceil(items / pageSize)
                    }
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchInsuranceReports', {page, 'page-size': pageSize, ...args})
                            .then((data) => {
                                commit('updateInsuranceReports', data)
                            })
                            .catch(() => {
                                dispatch('fetchInsuranceReports', {page, 'page-size': pageSize, ...args})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            if (fetch.length) commit('setInsuranceReportsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchInsuranceReportsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchInsuranceReportsChanged', inprogress: false})
                })
        },
        fetchInsuranceReportsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isInsuranceReportsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchInsuranceReportsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchInsuranceReports', args)
                .then((data) => {
                    commit('updateInsuranceReports', data)
                    return dispatch('fetchInsuranceReports', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterInsuranceReports', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchInsuranceReportsChanged', inprogress: false})
                });
        },

        fetchInsuranceForms({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchInsuranceForms', time: Date.now() / 1000})

                const params = getApiProps('insurance_forms', args)
                this.$api.insuranceforms.getAll({...params, ...with_removed})
                    .then((response) => {
                        commit('updateInsuranceForms', response.data)
                        resolve(true)
                    })
                    .catch((error) => {
                        reject(false)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchInsuranceForms', inprogress: false})
                    });
            })
        },

        saveInsuranceReport({dispatch}, insuranceReport) {
            let fn = (insuranceReport.id) ? 'updateInsuranceReport' : 'createInsuranceReport'
            return dispatch(fn, insuranceReport);
        },
        createInsuranceReport({commit, dispatch}, insuranceReport) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('insurance_reports')
                this.$api.insurancereports.create(insuranceReport, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateInsuranceReport', response.data)
                            dispatch('fetchInsuranceReportsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateInsuranceReport({commit, dispatch}, insuranceReport) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('insurance_reports')
                this.$api.insurancereports.update(insuranceReport.id, insuranceReport, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateInsuranceReport', response.data)
                            dispatch('fetchInsuranceReportsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteInsuranceReport({commit, dispatch}, id) {//remove
            return new Promise((resolve, reject) => {
                const params = getApiProps('insurance_reports')
                this.$api.insurancereports.delete(id, {...params, ...with_removed})//remove
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data.error)) {
                            if (!response.data) commit('deleteInsuranceReport', id)
                            else commit('updateInsuranceReport', response.data)
                            dispatch('fetchInsuranceReportsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        removedInsuranceReport({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('insurance_reports')
                this.$api.insurancereports.removed(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateInsuranceReport', response.data)
                            dispatch('fetchInsuranceReportsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        restoreInsuranceReport({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('insurance_reports')
                this.$api.insurancereports.restore(id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data.error) {
                            commit('updateInsuranceReport', response.data)
                            dispatch('fetchInsuranceReportsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        insuranceReportExport({getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.id) {
                    return reject(false)
                }

                //const params = getApiProps('insuranceReports')
                this.$api.insurancereports.export(args.id, args.format, args)//, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
    },
    mutations: {
        setInsuranceReportsFullLoad(state, FullLoad) {
            state.insuranceReportsFullLoad = state.insuranceReportsFullLoad || FullLoad
        },
        setInsuranceReportsLiteLoad(state, LitaLoad) {
            state.insuranceReportsLiteLoad = state.insuranceReportsLiteLoad || LitaLoad
        },

        setInsuranceReports(state, nInsuranceReports) {
            state.insuranceReports = nInsuranceReports
        },

        updateInsuranceReports(state, nInsuranceReports) {
            if (!state.insuranceReports.length) {
                nInsuranceReports = nInsuranceReports.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nInsuranceReports.sort(sortByName)
                state.insuranceReports = nInsuranceReports
                // const chunks = arraySplitIntoChunks(nInsuranceReports)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.insuranceReports.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nInsuranceReports.forEach(function (nInsuranceReport) {
                if (nInsuranceReport?.name) nInsuranceReport.name_ = nInsuranceReport.name.toLocaleLowerCase()
                let i = state.insuranceReports.findIndex(u => (u.id == nInsuranceReport.id))
                if (i < 0) {
                    state.insuranceReports.push(nInsuranceReport) //(Object.freeze(nInsuranceReport))
                } else
                if (!state.insuranceReportsFullLoad || state.insuranceReports[i][changedField] != nInsuranceReport[changedField]) {
                    updateObjectByDiff(state.insuranceReports[i], nInsuranceReport)
                    // delete nInsuranceReport.id
                    // nInsuranceReport = {...state.insuranceReports[i], ...nInsuranceReport}
                    // state.insuranceReports[i] = nInsuranceReport //Object.freeze(nInsuranceReport)
                }
            })

        },
        filterInsuranceReports(state, nInsuranceReports) {
            // let Ids = state.insuranceReports.map(u=> u.id)
            let nIds = nInsuranceReports.map(u => u.id)
            let removedIds = state.insuranceReports.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.insuranceReports.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.insuranceReports.splice(i, 1)
                }
            })
        },
        updateInsuranceReport(state, nInsuranceReport) {
            if (nInsuranceReport?.name) nInsuranceReport.name_ = nInsuranceReport.name.toLocaleLowerCase()
            let i = state.insuranceReports.findIndex(u => (u.id == nInsuranceReport.id))
            if (i < 0) {
                state.insuranceReports.push(nInsuranceReport) //(Object.freeze(nInsuranceReport))
            } else
            if (!state.insuranceReportsFullLoad || state.insuranceReports[i][changedField] != nInsuranceReport[changedField]) {
                updateObjectByDiff(state.insuranceReports[i], nInsuranceReport)
                // delete nInsuranceReport.id
                // nInsuranceReport = {...state.insuranceReports[i], ...nInsuranceReport}
                // state.insuranceReports[i] = nInsuranceReport //Object.freeze(nInsuranceReport)
            }
        },
        deleteInsuranceReport(state, id) {
            let i = state.insuranceReports.findIndex(u => (u.id == id))
            if (i != -1) {
                state.insuranceReports.splice(i, 1)
            }
        },

        updateInsuranceForms(state, nInsuranceForms) {
            if (!state.insuranceForms.length) {
                nInsuranceForms = nInsuranceForms.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nInsuranceForms.sort(sortByName)
                state.insuranceForms = nInsuranceForms
                return true
            }

            nInsuranceForms.forEach(function (nInsuranceForm) {
                if (nInsuranceForm?.name) nInsuranceForm.name_ = nInsuranceForm.name.toLocaleLowerCase()
                let i = state.insuranceForms.findIndex(u => (u.id == nInsuranceForm.id))
                if (i < 0) {
                    state.insuranceForms.push(nInsuranceForm) //(Object.freeze(nInsuranceForm))
                } else
                if (!state.insuranceFormsFullLoad || state.insuranceForms[i][changedField] != nInsuranceForm[changedField]) {
                    updateObjectByDiff(state.insuranceForms[i], nInsuranceForm)
                    // delete nInsuranceForm.id
                    // nInsuranceForm = {...state.insuranceForms[i], ...nInsuranceForm}
                    // state.insuranceForms[i] = nInsuranceForm //Object.freeze(nInsuranceForm)
                }
            })

        },

        clearInsuranceReports(state) {
            state.insuranceReports = []
            state.insuranceReportsFullLoad = false
        },
    },
    getters: {
        isInsuranceReportsFullLoad(state) {
            return state.insuranceReportsFullLoad
        },
        isInsuranceReportsLiteLoad(state) {
            return state.insuranceReportsLiteLoad
        },
        getInsuranceReports(state) {
            return state.insuranceReports
        },
        getInsuranceReportsByIds(state) {
            return state.insuranceReports.reduce((insuranceReportsByIds, insuranceReport) => {
                insuranceReportsByIds[insuranceReport.id] = insuranceReport
                return insuranceReportsByIds
            }, {})
        },
        sortedInsuranceReportsIds(state) {
            let insuranceReports = state.insuranceReports
            insuranceReports.sort(sortByName)
            return insuranceReports.map(u => u.id)
        },
        getInsuranceForms(state) {
            return state.insuranceForms
        },
        insuranceFormsByIds(state) {
            return state.insuranceForms.reduce((insuranceReportnFormsByIds, insuranceForm) => {
                insuranceReportnFormsByIds[insuranceForm.id] = insuranceForm
                return insuranceReportnFormsByIds
            }, {})
        },
    }
}
