const RESOURCE_NAME = '/screenshots';

export default axios => ({
    getLast(groupId, last, params) {
        let url = ''
        if (groupId > '') url += `/unit-group/${groupId}`
        url += `${RESOURCE_NAME}/last`
        //?last/(n (sec|second|min|minute|hour|day|month)(s?))
        if (last > '') url+= `/${last}`
        return axios.get(url, {params});
    },
    get4Date(groupId, date, params) {
        if (!(groupId > '')) {
            //?date=y-m-d
            return axios.get(`${RESOURCE_NAME}?date=${date}`, {params});
        }
        return axios.get(`/unit-group/${groupId}${RESOURCE_NAME}/${date}`, {params});
    },
    get4Period(groupId, from, to, params) {
        if (!(groupId > '')) {
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}?start-time=${from}&end-time=${to}`, {params});
        }
        return axios.get(`/unit-group/${groupId}${RESOURCE_NAME}/${from}/${to}`, {params});
    },
    getLastSuccessful(groupId, params) {
        if (!(groupId > '')) {
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}/last-successful`, {params});
        }
        return axios.get(`/unit-group/${groupId}${RESOURCE_NAME}/last-successful`, {params});
    },
    getStatistics(groupId, params) {
        return axios.get(`/unit-group/${groupId}${RESOURCE_NAME}/statistics`, {params});
    },
});