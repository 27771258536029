// import consts from "@/consts"
import {arrayFilter_OnlyUnique, getApiProps, updateObjectByDiff} from "@/lib/lib";
import i18n from "@/i18n";
const changedField = 'changed__time'
export default {
    state: {
        commands_index: [],
        commands: [],
        lastVisitCommands: null,
        commandsFullLoad: false,
        commandsLiteLoad: false,
    },
    actions: {
        fetchCommands({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchCommands', time: Date.now() / 1000})

                let params = getApiProps('commands', {withAddress: 1, ...args.params})
                //if(!args.force) params.lastTimes = getters.getCommandsLastTimeByUnitIds
                this.$api.units.getAllCmd(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeCommands', response.data)
                            commit('setCommandsFullLoad', true)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchCommands', inprogress: false})
                    });
            })
        },
        fetchLastCommands({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastCommands', time: Date.now() / 1000})

                let last = args?.last || '1 minutes'
                let params = getApiProps('commands', {withAddress: 1, ...args.params})
                //if(!args.force) params.lastTimes = getters.getCommandsLastTimeByUnitIds
                this.$api.commands.getLast(last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeCommands', response.data)
                            commit('setCommandsFullLoad', true)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastCommands', inprogress: false})
                    });
            })
        },
        fetchCommands4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let index = getters.getCommandsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                    if (index) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchCommands4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastCommands', time: Date.now() / 1000})
                }

                let params = getApiProps('commands', {withAddress: 1, ...args.params})
                this.$api.commands.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeCommands', response.data)
                            commit('setCommandsFullLoad', true)
                            if (!params.limit || response.data.length < params.limit) {
                                commit('setCommandsIndex', {...args, count: response.data.length})
                                if (!args.unitId && response.data.length) {
                                    response.data
                                        .map(e => e.unit_id)
                                        .filter(arrayFilter_OnlyUnique)
                                        .forEach(unitId => {
                                            commit('setCommandsIndex', {...args, unitId, count: 1})
                                        })
                                }
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchCommands4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastCommands', inprogress: false})
                        }
                    });
            })
        },
        fetchCommands4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let command = getters.getCommands.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                    if (command) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchCommands4Date', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastCommands', time: Date.now() / 1000})
                }

                let params = getApiProps('commands', {withAddress: 1, ...args.params})
                this.$api.commands.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeCommands', response.data)
                            commit('setCommandsFullLoad', true)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchCommands4Date', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastCommands', inprogress: false})
                        }
                    });
            })
        },
        reloadCommands({commit, dispatch}, args) {
            commit('clearCommands')
            return dispatch('fetchCommands4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        setCommandsFullLoad(state, FullLoad) {
            state.commandsFullLoad = state.commandsFullLoad || FullLoad
        },
        setCommandsLiteLoad(state, LitaLoad) {
            state.commandsLiteLoad = state.commandsLiteLoad || LitaLoad
        },
        setCommandsIndex(state, params) {
            let i = state.commands_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.commands_index.push(Object.freeze({...params}))
            } else {
                state.commands_index[i] = Object.freeze({...state.commands_index[i], ...params})
            }
        },
        storeCommands(state, nCommands) {
            nCommands = nCommands
                .filter((e, i, _commands) => (i === _commands.findIndex(_e => _e.xid == e.xid)))

            if(!state?.commands?.length) {
                state.commands = nCommands
                return true
            }

            nCommands.forEach(function (nCommand) {
                let i = state.commands.findIndex(u => (u.xid == nCommand.xid))
                if (i < 0) {
                    state.commands.push(nCommand) //(Object.freeze(nCommand))
                } else
                if (state.commands[i][changedField] != nCommand[changedField] && state.commands[i][changedField]>0) {
                    updateObjectByDiff(state.commands[i], nCommand)
                }
            })
        },
        clearCommands(state) {
            state.commands = [];
            state.commands_index = [];
            state.commandsFullLoad = false;
            state.commandsLiteLoad = false;
        },
        setLastVisitCommands(state, time) {
            state.lastVisitCommands = time
        },
    },
    getters: {
        isCommandsFullLoad(state) {
            return state.commandsFullLoad
        },
        isCommandsLiteLoad(state) {
            return state.commandsLiteLoad
        },
        getLastVisitCommands(state, getters) {
            return state.lastVisitCommands || (localStorage.getItem(getters.getUserStorageKey + '.commands.lastVisit') * 1)
        },
        getNewCommandsCount(state, getters) {
            let lastVisit = getters.getLastVisitCommands
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.commands.filter(e => e.time?.utc > lastVisit).length
        },
        getCommandsIndex(state) {
            return state.commands_index
        },
        getCommands(state) {
            return state.commands
        },
        getCommandsI18nMessages(state) {
            return state.commands
                .map((commandsTextByXid, command) => {
                    let message = command?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = command?.message[match[1]] || ''
                            let translateKey = 'message.' + 'commands' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: command.type, xid: command.xid, message};
                })
        },
        getCommandsI18nMessagesByXid(state) {
            return state.commands
                .reduce((commandsTextByXid, command) => {
                    let message = command?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = command?.message[match[1]] || ''
                            let translateKey = 'message.' + 'commands' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    commandsTextByXid[command.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return commandsTextByXid;
                }, {})
        },
        getCommandsLastTimeByUnitIds(state) {
            return state.commands
                //.filter(e => e?.time?.utc >= getters.getTimeToday)
                .reduce((timeByUnitIds, command) => {
                    timeByUnitIds[command.unit_id] = command?.time?.utc > timeByUnitIds[command.unit_id] ? command?.time?.utc : timeByUnitIds[command.unit_id]
                    return timeByUnitIds
                }, {})
        },
    }
}
