import {getMainParentRouteName} from "@/lib/lib";

export default {
    computed: {
        routeName() {
            return this.$route?.name
        },
        routeFilter() {
            return this.$route?.params
        },
        routeQueryFilter() {
            return this.$route?.query
        },
        routeMeta() {
            return this.$route?.meta
        },
        setRoute() {
            return this.routeMeta?.setRoute
        },
        routeTemplate() {
            return this.$route?.params?.template
        },
        mainRouteName(){
            return this.routeMeta?.mainRouteName
        },
        mainParentRouteName() {
            return getMainParentRouteName(this.$route.name)
        },
    }
}