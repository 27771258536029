import {mapActions} from "vuex";
import {useToast} from "vue-toastification";

export default {
    data() {
        return {
            toast: useToast(),
        }
    },
    methods: {
        ...mapActions([
            "sendUnitCmd",
        ]),
        modalClose() {
            //
        },
        sendCmd(cmd, params={}){
            if(!this.unitId) return false;
            return this.sendUnitCmd({ id: this.unitId, cmd, params })
                .then((response) => {
                    //console.log(response)
                    if (response?.data && !response.data?.error) {
                        this.toast.success(this.$t("success!"))
                        return true
                    } else
                    {
                        console.error(response.data);
                        let message = this.$t("error!")
                        if (response?.data) {
                            message = response?.data?.message || response?.data?.errors || message
                        }
                        this.toast.error(message)
                        return false
                    }
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        console.error(error.response)
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    this.toast.error(this.$t("error!"))
                    return false;
                    //console.error(error.config);
                })
                .finally(() => this.modalClose())
        },
    }
}