// import consts from "@/consts"
import {arrayFilter_OnlyUnique, getApiProps} from "@/lib/lib";
import i18n from "@/i18n";
const alertsTypes = ['alert'];
export default {
    state: {
        events_index: [],
        events: [],
        lastVisitEvents: null,
        lastVisitAlerts: null,
    },
    actions: {
        fetchEvents({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchEvents', time: Date.now() / 1000})

                let params = getApiProps('events', {withAddress: 1, ...args.params})
                //if(!args.force) params.lastTimes = getters.getEventsLastTimeByUnitIds
                this.$api.events.getAll(args.id, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeEvents', response.data)
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchEvents', inprogress: false})
                    });
            })
        },
        fetchLastEvents({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchLastEvents', time: Date.now() / 1000})

                let params = getApiProps('events', {withAddress: 1, ...args.params})
                //if(!args.force) params.lastTimes = getters.getEventsLastTimeByUnitIds
                this.$api.events.getLast(args.last, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeEvents', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchLastEvents', inprogress: false})
                    });
            })
        },
        fetchEvents4Date({commit, dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                args = {unitId: '', date: '', ...args}
                if (args.date == 'today') args.date = getters.getDateToday
                if (!getters.apiToken || !(args.date > '')) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let index = getters.getEventsIndex.find(i => (i.unit_id == args.unitId && i.date == args.date))
                    if (index) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchEvents4Date', time: Date.now() / 1000})
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastEvents', time: Date.now() / 1000})
                }

                let params = getApiProps('events', {withAddress: 1, ...args.params})
                this.$api.events.get4Date(args.unitId, args.date, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeEvents', response.data)
                            if (!params.limit || response.data.length < params.limit) {
                                commit('setEventsIndex', {...args, count: response.data.length})
                                if (!args.unitId && response.data.length) {
                                    response.data
                                        .map(e => e.unit_id)
                                        .filter(arrayFilter_OnlyUnique)
                                        .forEach(unitId => {
                                            commit('setEventsIndex', {...args, unitId, count: 1})
                                        })
                                }
                            }
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchEvents4Date', inprogress: false})
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastEvents', inprogress: false})
                        }
                    });
            })
        },
        fetchEvents4Period({dispatch, commit, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken || !args.from || !args.to) {//!args.unitId ||
                    return reject(false)
                }
                if (args.unitId > 0) {
                    let event = getters.getEvents.find(e => (e.unit_id == args.unitId && e.time?.utc >= args.from && e.time?.utc <= args.to))
                    if (event) {
                        return resolve(true)
                    }
                }

                dispatch('setLastCall', {name: 'fetchEvents4Date', time: Date.now() / 1000})
                /* getters.getTimeToday+24h and args.from/to intersect*/
                if (args.date == getters.getDateToday) {
                    dispatch('setLastCall', {name: 'fetchLastEvents', time: Date.now() / 1000})
                }

                let params = getApiProps('events', {withAddress: 1, ...args.params})
                this.$api.events.get4Period(args.unitId, args.from, args.to, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('storeEvents', response.data)
                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchEvents4Date', inprogress: false})
                        /* getters.getTimeToday+24h and args.from/to intersect*/
                        if (args.date == getters.getDateToday) {
                            dispatch('setLastCall', {name: 'fetchLastEvents', inprogress: false})
                        }
                    });
            })
        },
        reloadEvents({commit, dispatch}, args) {
            commit('clearEvents')
            return dispatch('fetchEvents4Date', {date: 'today', ...args})
        },

        //sayHello() {}
    },
    mutations: {
        setEventsIndex(state, params) {
            let i = state.events_index.findIndex(i => i.date == params.date && i.unitId == params.unitId)
            if (i < 0) {
                state.events_index.push(Object.freeze({...params}))
            } else {
                state.events_index[i] = Object.freeze({...state.events_index[i], ...params})
            }
        },
        storeEvents(state, nEvents) {
            nEvents = nEvents
                .filter((e, i, _events) => (i === _events.findIndex(_e => _e.xid == e.xid)))
                .filter(e => !state.events.find(se => se.xid == e.xid))
                .map(e => Object.freeze(e))

            // if(nEvents.length) state.events.push(...nEvents)
            if (!nEvents.length) return false
            if (!state.events.length) {
                state.events = nEvents
                return true
            }
            state.events.push(...nEvents)

            //vue recommended
            // const chunks = arraySplitIntoChunks(nEvents)//.reverse();
            // const pushOnRenderTask = () => {
            //     if (chunks.length === 0) return;
            //     let chunk = chunks.pop();
            //     state.events.push(...chunk);
            //     requestAnimationFrame(pushOnRenderTask);
            // }
            // pushOnRenderTask();
        },
        clearEvents(state) {
            state.events = [];
            state.events_index = [];
        },
        setLastVisitEvents(state, time) {
            state.lastVisitEvents = time
        },
        setLastVisitAlerts(state, time) {
            state.lastVisitAlerts = time
        },
    },
    getters: {
        getLastVisitEvents(state, getters) {
            return state.lastVisitCommands || (localStorage.getItem(getters.getUserStorageKey + '.events.lastVisit') * 1)
        },
        getLastVisitAlerts(state, getters) {
            return state.lastVisitAlerts || (localStorage.getItem(getters.getUserStorageKey + '.alerts.lastVisit') * 1)
        },
        getNewEventsCount(state, getters) {
            let lastVisit = getters.getLastVisitEvents
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            return state.events.filter(e => (e.time?.utc > lastVisit) && !alertsTypes.includes(e['sub-type'])).length
        },
        getNewAlertsCount(state, getters) {
            let lastVisit = getters.getLastVisitAlerts
            if (getters.getTimeToday > lastVisit) lastVisit = getters.getTimeToday
            let event = state.events.filter(e => (e.time?.utc > lastVisit) && alertsTypes.includes(e['sub-type'])).length
            return event;
        },
        getEventsIndex(state) {
            return state.events_index
        },
        getEvents(state) {
            return state.events
        },
        getEventsI18nMessages(state) {
            return state.events
                .map((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    return {type: event.type, xid: event.xid, message};
                })
        },
        getEventsI18nMessagesByXid(state) {
            return state.events
                .reduce((eventsTextByXid, event) => {
                    let message = event?.message?.msg;
                    if (message) {
                        let matches = message.matchAll(/\$t{([\w]+)}/gi);
                        Array.from(matches).forEach(match => {
                            let str = event?.message[match[1]] || ''
                            let translateKey = 'message.' + 'events' + '.' + str
                            str = i18n.global.te(translateKey) ? i18n.global.t(translateKey) : str
                            message = message.replace(match[0], str);
                        })
                    }
                    eventsTextByXid[event.xid] = message;// || i18n.global.t('message.Unknown')//???
                    return eventsTextByXid;
                }, {})
        },
        getEventsLastTimeByUnitIds(state) {
            return state.events
                //.filter(e => e?.time?.utc >= getters.getTimeToday)
                .reduce((timeByUnitIds, event) => {
                    timeByUnitIds[event.unit_id] = event?.time?.utc > timeByUnitIds[event.unit_id] ? event?.time?.utc : timeByUnitIds[event.unit_id]
                    return timeByUnitIds
                }, {})
        },
    }
}
