const RESOURCE_NAME = '/screenshots';

export default axios => ({
    getLast(unitId, last, params) {
        let url = ''
        if (unitId > '') url = `/unit/${unitId}`
        url += `${RESOURCE_NAME}/last`
        //?last/(n (sec|second|min|minute|hour|day|month)(s?))
        if (last > '') url+= `/${last}`
        return axios.get(url, {params});
    },
    get4Date(unitId, date, params) {
        if (!(unitId > '')) {
            //?date=y-m-d
            return axios.get(`${RESOURCE_NAME}?date=${date}`, {params});
        }
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/${date}`, {params});
    },
    get4Period(unitId, from, to, params) {
        if (!(unitId > '')) {
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}?start-time=${from}&end-time=${to}`, {params});
        }
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/${from}/${to}`, {params});
    },
    getLastSuccessful(unitId, params) {
        if (!(unitId > '')) {
            //?start-time=int&end-time=int
            return axios.get(`${RESOURCE_NAME}/last-successful`, {params});
        }
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/last-successful`, {params});
    },
    getStatistics(unitId, params) {
        return axios.get(`/unit/${unitId}${RESOURCE_NAME}/statistics`, {params});
    },
});